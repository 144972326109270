document.addEventListener("DOMContentLoaded", function () {
  // Spoiler functionality
  jQuery(".spoiler > .head").on("click", function (t) {
    jQuery(this).parent("div.spoiler").children(".cont").stop().slideToggle(300).toggleClass("active"),
      jQuery(this).toggleClass("active"),
      t.preventDefault();
  });

  // Scroll event for header
  $(window).on('scroll', function () {
    if ($('.fs-menu').hasClass('open-nav')) {
      $('#img-logo').attr('src', './images/logo_b.svg');
    } else if (window.scrollY > 0) {
      $('header.header').addClass('sticky');
      $('#img-logo').attr('src', './images/logo_b.svg');
    } else {
      $('header.header').removeClass('sticky');
      $('#img-logo').attr('src', './images/logo_w.svg');
    }
  });

  // Burger menu functionality
  $('.b-menu').on('click', function () {
    $(this).toggleClass("open-nav");
    $('.fs-menu').toggleClass("open-nav");

    if ($('.fs-menu').hasClass('open-nav')) {
      $('#img-logo').attr('src', './images/logo_b.svg');
      $('body').addClass('no-scroll');
    } else {
      $('body').removeClass('no-scroll');
      if (window.scrollY > 0) {
        $('#img-logo').attr('src', './images/logo_b.svg');
      } else {
        $('#img-logo').attr('src', './images/logo_w.svg');
      }
    }
  });

  // Smooth scroll functionality
  document.querySelectorAll('.menu-item a').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault();
      document.querySelector(this.getAttribute('href')).scrollIntoView({ behavior: 'smooth' });
      $('body').removeClass('no-scroll');
      $('.b-menu').toggleClass("open-nav");
      $('.fs-menu').toggleClass("open-nav");
    });
  });

  // Swiper initializations
  const portfolio = new Swiper(".portfolio-list", {
    slidesPerView: 1.3,
    centeredSlides: 0,
    loop: true,
    breakpoints: {
      768: {
        slidesPerView: 2,
        centeredSlides: false,
      },
      1024: {
        slidesPerView: 2,
      }
    },
    pagination: {
      el: ".portfolio-list .swiper-pagination",
      clickable: true
    },
    navigation: {
      nextEl: ".portfolio-list .swiper-button-next",
      prevEl: ".portfolio-list .swiper-button-prev"
    }
  });

  const developSwiper = new Swiper('.swiper-develop-container', {
    slidesPerView: 1,
    slideToClickedSlide: true,
    centeredSlides: true,
    spaceBetween: 10,
    loop: true,
    breakpoints: {
      640: {
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 0,
      },
    },
    pagination: {
      el: '.section-benefits .swiper-pagination',
      clickable: true,
    }
  });

  // Modal functionality
  const modal = $('.fullscreen-modal');
  const close = $('.close');

  $('[data-modal]').on('click', function (e) {
    e.preventDefault();
    modal.addClass('active');
  });

  close.on('click', function () {
    modal.removeClass('active');
    clearFormErrors('#popupForm');
  });

  emailjs.init("WPdTefXn8sl82vNwD");

  // Настройка валидации формы
  $("#blockForm, #popupForm").each(function () {
    $(this).validate({
      rules: {
        name: {
          required: true
        },
        email: {
          required: true,
          email: true
        },
        message: {
          required: true
        },
        terms: {
          required: true
        }
      },
      messages: {
        name: {
          required: "Please enter your name"
        },
        email: {
          required: "Please enter your email",
          email: "Please enter a valid email address"
        },
        message: {
          required: "Please enter your message"
        },
        terms: {
          required: "Please agree to the terms and conditions"
        }
      },
      errorPlacement: function (error, element) {
        const name = element.attr("name");
        if (name === "terms") {
          error.appendTo(element.closest(".wr-box").find(".error"));
        } else {
          error.appendTo(element.next(".error"));
        }
      },
      submitHandler: function (form) {
        sendEmail(form);
      }
    });
  });

  function sendEmail(form) {
    const serviceID = "service_n86cvb6";
    const templateID = "template_hw0fs4m";
    const templateParams = {
      name: form.name.value,
      email: form.email.value,
      message: form.message.value
    };

    emailjs.send(serviceID, templateID, templateParams)
      .then(response => {
        showNotification('success');
        if (form.id === "popupForm") {
          closeModal();
        }
        form.reset();
      }, error => {
        showNotification('error');
        console.error('Error:', error);
      });
  }

  function showNotification(type) {
    const notification = type === 'success' ? document.getElementById('successPopup') : document.getElementById('errorPopup');
    notification.style.display = 'block';
    setTimeout(() => {
      notification.style.display = 'none';
    }, 3000);
  }

  function closeModal() {
    modal.removeClass('active');
    clearFormErrors('#popupForm');
  }

  function clearFormErrors(formSelector) {
    $(formSelector).find(".error").text("");  // Clear error texts
  }
});
